import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class ShopLocationService {
  public baseUrl = environment.apiBaseUrl + "/location";
  constructor(private http: HttpClient) {}

  getAllData() {
    return this.http.get(this.baseUrl + "/");
  }
  saveLocation(data) {
    return this.http.post(this.baseUrl + "/", data);
  }
  updateLocationStatus(data) {
    return this.http.put(this.baseUrl + "/status", data);
  }
  deleteLocation(id) {
    var params = new HttpParams().set("id", id);
    return this.http.delete(`${this.baseUrl}/delete`, { params });
  }
}
