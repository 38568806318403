import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  public baseUrl = environment.apiBaseUrl + '/stock';
  constructor(private http: HttpClient) { }

  getAllStock = () => {
    return this.http.get(`${this.baseUrl}/getAll`);
  }
  updateStock= (data) =>{
    return this.http.patch(`${this.baseUrl}/updateStock`,data);
  }
  searchStock = (data) => {
    return this.http.post(`${this.baseUrl}/searchStock`, data);
  }

  getStockByFarmId(data){
    return this.http.post(`${this.baseUrl}/getStockByFarm`, data);
  }
  getStockByFarmIdExcel(data){
    const title = 'Product Export Report';
    return this.http.post(`${this.baseUrl}/getStockByFarmExcel`,data ,{
      params: new HttpParams().append('token', localStorage.getItem('token')),
      observe: 'response', responseType: 'text'
    }).subscribe(r => { saveAs(new Blob([r.body], { type: 'text/csv' }), title + '.csv'); });
    
  }

  fileUploadByFarmId(files){
    return this.http.post(`${this.baseUrl}/fileUploadByFarmId`,files);
  }
}
