import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public baseUrl = environment.apiBaseUrl + '/user';
  constructor(private http: HttpClient) { }

  getAllUser = () => {
    return this.http.get(`${this.baseUrl}/getAll`);
  }

  saveUser = (data) => {
    return this.http.post(`${this.baseUrl}/save`,data);
  }

  updateUser = (data) => {
    return this.http.patch(`${this.baseUrl}/update`,data);
  }

  updateUserPassword = (data) => {
    return this.http.patch(`${this.baseUrl}/update-password`,data);
  }
  deleteUser = (id) => {
    var params = new HttpParams().set('id', id);
    return this.http.delete(`${this.baseUrl}/delete`, { params });
  }

  updateUserStatus = (data) => {
    return this.http.put(`${this.baseUrl}/updateStatus`,data);
  }
}
