import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  public baseUrl = environment.apiBaseUrl + '/customer';
  constructor(private http: HttpClient) { }

  getCustomerDeatails(id){
    var params = new HttpParams().set('id', id);
  return  this.http.get(`${this.baseUrl}/getCustomerById`,{params})
  }
  saveCustomer(data){
    return this.http.post(`${this.baseUrl}/signup`,data);
  }

  saveAddress(data){
    return this.http.post(`${this.baseUrl}/retail-save-address`,data)
  }

  getAllCustomer(){
    return  this.http.get(`${this.baseUrl}/getAll`)
  }
  getCustomerByPage(data){
    return this.http.put(`${this.baseUrl}/getOrdersByCustomer`,data)
  }
  getCustomerOrders(data){
    return this.http.post(`${this.baseUrl}/getCustomerOrders`,data);
  }
  // getOrdersbyCustomer(){
  //   return this.http.get(`${this.baseUrl}/getOrdersByCustomer`)
  // }
  getCustomerCount(){
    return this.http.get(`${this.baseUrl}/getCount`)
  }

  getCustomerCategoryCounts(data){
    return this.http.post(`${this.baseUrl}/getCategoryCounts`,data);
  }

  changeType(data){
    return this.http.patch(`${this.baseUrl}/changeType`,data);
  }

  updateCustomerPlan(data){
    return this.http.patch(`${this.baseUrl}/changePlan`,data);
  }

  updateReward(data){
    return this.http.patch(`${this.baseUrl}/updateReward`,data);
  }

  changeCODTypeStatus(data){
    return this.http.patch(`${this.baseUrl}/updateCOD`,data);
  }

  changeDeliveryChargeStatus(data){
    return this.http.patch(`${this.baseUrl}/updateDelivery`,data);
  }

  updateCustomer(data){
    return this.http.put(`${this.baseUrl}/update`,data);
  }

  updateAddress(data){
    return this.http.post(`${this.baseUrl}/update-address-data`,data)
  }

  deleteCustomer(data){
    return this.http.put(`${this.baseUrl}/delete`,data)
  }

  updateRefferedCustomer(data){
    return this.http.post(`${this.baseUrl}/reffer`,data)

  }

  getRefferalCustomer(data){
    return this.http.put(`${this.baseUrl}/referralCus`,data)
  }
  getCustomerByMobile(data){
    return this.http.put(`${this.baseUrl}/byMobile`,data)
  }

  getCustomerByType(data){
    return this.http.put(`${this.baseUrl}/cusByType`,data)
  }

  getReferralCustomer(data){
    return this.http.put(`${this.baseUrl}/getReferralCus`,data)
  }

  updateCustomerDelivery(data){
    return this.http.put(`${this.baseUrl}/updateCODDeliverys`,data);
  }
  searchCustomer(data){
    return this.http.put(`${this.baseUrl}/filterCustomers`,data)
  }
}
