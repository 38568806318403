import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  public baseUrl = environment.apiBaseUrl + '/vendor';
  constructor(private http: HttpClient) { }

   getAllVendors = () => {
    return this.http.get(`${this.baseUrl}/getAll`);
  }

  getActiveVendor = () => {
    return this.http.get(`${this.baseUrl}/active`);
  }

  saveVendor = (data) => {
    return this.http.post(`${this.baseUrl}/save`,data);
  }

  updateVendor = (data) => {
    return this.http.patch(`${this.baseUrl}/update`,data);
  }

  deleteVendor = (id) => {
    var params = new HttpParams().set('id', id);
    return this.http.delete(`${this.baseUrl}/delete`, { params });
  }

  updateVendorStatus = (data) => {
    return this.http.put(`${this.baseUrl}/status`,data);
  }
}
