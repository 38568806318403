import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GstServiceService {

  public baseUrl = environment.apiBaseUrl + '/gst';
  constructor(private http: HttpClient) { }

  saveGst(data){
    return this.http.post(this.baseUrl+'/',data)
  }

  getAllGst(){
    return this.http.get(this.baseUrl+'/')
  }
  updategstTypeStatus(details){
    return this.http.put(this.baseUrl+'/status',details)
  }
  deleteGstType = (id) => {
    var params = new HttpParams().set('id', id);
    return this.http.delete(`${this.baseUrl}/`, { params });
  }

}
