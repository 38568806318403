import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  public baseUrl = environment.apiBaseUrl + '/product';
  constructor(private http: HttpClient) { }

  getAllProduct = () => {
    return this.http.get(`${this.baseUrl}/getAll`);
  }

  saveProduct = (data) => {
    return this.http.post(`${this.baseUrl}/save`,data);
  }

  updateProduct = (data) => {
    return this.http.patch(`${this.baseUrl}/update`,data);
  }

  deleteProduct = (id) => {
    var params = new HttpParams().set('id', id);
    return this.http.delete(`${this.baseUrl}/delete`, { params });
  }

  updateProductStatus = (data) => {
    return this.http.put(`${this.baseUrl}/updateStatus`,data);
  }

  getActiveProduct = () => {
    return this.http.get(`${this.baseUrl}/active`);
  }

  getProductByProductCat=(data)=>{
    return this.http.post(`${this.baseUrl}/byProductCat`,data);
  }


}
